#special-leaves {
	position: fixed;
	pointer-events: none;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0px;

	@media (max-width: 768px) {
		display: none;
	}
}

#special-leaves .set {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
}
#special-leaves .set div {
	position: absolute;
	display: block;
	opacity: 0.2;
}
#special-leaves .set div:nth-child(1) {
	left: 20%;
	animation: animate 15s linear infinite;
	animation-delay: -7s;
}
#special-leaves .set div:nth-child(2) {
	left: 50%;
	animation: animate 20s linear infinite;
	animation-delay: -5s;
}
#special-leaves .set div:nth-child(3) {
	left: 70%;
	animation: animate 20s linear infinite;
	animation-delay: 0s;
}
#special-leaves .set div:nth-child(4) {
	left: 0%;
	animation: animate 15s linear infinite;
	animation-delay: -5s;
}
#special-leaves .set div:nth-child(5) {
	left: 85%;
	animation: animate 18s linear infinite;
	animation-delay: -10s;
}
#special-leaves .set div:nth-child(6) {
	left: 20%;
	animation: animate 15s linear infinite;
	animation-delay: -7s;
}
#special-leaves .set div:nth-child(7) {
	left: 0%;
	animation: animate 12s linear infinite;
}
#special-leaves .set div:nth-child(8) {
	left: 60%;
	animation: animate 15s linear infinite;
}

@keyframes animate {
	0% {
		opacity: 0;
		top: -10%;
		transform: translateX(20px) rotate(0deg);
	}
	10% {
		opacity: 1;
	}
	20% {
		transform: translateX(-20px) rotate(45deg);
	}
	40% {
		transform: translateX(-20px) rotate(90deg);
	}
	60% {
		transform: translateX(20px) rotate(180deg);
	}
	80% {
		transform: translateX(-20px) rotate(180deg);
	}
	100% {
		top: 110%;
		transform: translateX(-20px) rotate(225deg);
	}
}
