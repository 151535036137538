footer {
    background-color: #f9f9f9;
    padding: 20px 0;
    margin-top: 100px;

    p {
        color: #3c3c3cb3;
        line-height: 24px;
        font-size: 12px;
        margin: 0;
        text-align: center;
    }
}