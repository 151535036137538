.block-features {
	padding: 50px;

	.feature-item {
		display: flex;
		flex-direction: row;
		margin-bottom: 80px;
		gap: 20px;

		.left-side {
			width: 520px;
		}

		.right-side img {
			box-shadow: 0px 0px 20px 0px #c9c9c9;
		}

		h2 {
			font-size: 52px;
			font-weight: bold;
			font-family: roboto;
			color: #0a2540;
			margin: 0px 0px 20px 0px;
		}

		p {
			font-size: 18px;
			color: #425466;
			margin: 0px 0px 6px 0px;
			line-height: 26px;
		}
	}

	@media only screen and (max-width: 750px) {
		.feature-item {
			flex-direction: column;
			gap: 20px;

			.left-side {
				width: 100%;
			}
		}
		img {
			width: 100%;
		}
	}
}

.block-wrapper-features {
	width: 70%;
	@media only screen and (max-width: 750px) {
		width: 90%;
	}
}
