.breadcrumb-container {

    ul {
        display: flex;
        justify-content: start;
        flex-direction: row;
        gap: 20px;
    }

    li {
        &.active a {
            color: #39b54b;
        }
        &::after {
            content: " > ";
            padding-left: 15px;
        }
        
    }

    li:last-child:after {
        content: "";
    }

    
}

@media only screen and (max-width: 349px) {
    .breadcrumb-container {
        display: none;
    }
}