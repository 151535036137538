.home-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 130px;

	p {
		font-family: "Montserrat", sans-serif;
	}

	.tree {
		margin-bottom: 20px;

		img {
			width: 280px;
		}
	}

	.title {
		h1 {
			font-family: "Parisienne", sans-serif;
			font-size: 122px;
			font-weight: bold;
			margin: 0px;
			padding-right: 40px;

			@media only screen and (max-width: 750px) {
				font-size: 75px;
			}
		}
	}

	.moto {
		width: 300px;
		p {
			text-align: center;
			font-size: 22px;
			margin-top: 0px;
		}
	}

	.search-btn-wrapper {
		margin: 20px 0px 40px;
		a {
			display: inline-block;
			background-color: #65c271;
			color: white;
			border: 1px solid #65c271;
			border-radius: 8px;
			padding: 18px 70px;
			margin-bottom: 16px;
			cursor: pointer;

			&:hover {
				// background-color: white;
				color: black;
			}
		}

		.home-item.donate-link a {
			background-color: #f4364c;
			border: 1px solid #f4364c;
		}
	}

	.small-desc {
		width: 70%;
		text-align: center;
	}

	@media only screen and (max-width: 750px) {
		margin-top: 100px;
	}
}
