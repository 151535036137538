@keyframes movement {
    // 0% { transform: translateX(0px); }
    // 50% { transform: translateX(10px); }
    // 100% { transform: translateX(0px); }
}

@keyframes headRotate {
    0% { transform: rotate(0); }
    50% { transform: rotate(2deg); }
    100% { transform: rotate(0); }
}

.block-person-detail {
    .left {
        img {
            animation-name: movement;
            animation-duration: 4s;
            animation-iteration-count: infinite;
        }

        .person-head {
            animation-name: movement;
            animation-duration: 4s;
            animation-iteration-count: infinite;
        }
        .person-head-wrapper {
            // animation-name: headRotate;
            // animation-duration: 4s;
            // animation-iteration-count: infinite;
        }
        .person-body-wrapper {
            position: absolute;
            top: 120px;
            left: 40px;
        }
    }
}