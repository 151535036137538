.onboarding-page {
	display: flex;
	position: relative;
	width: 100%;
	height: 100vh;
	background-color: #fff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	overflow: hidden;

	.btn-back {
		position: absolute;
		top: 20px;
		left: 20px;
		cursor: pointer;
	}

	p {
		margin: 6px;
	}

	.left {
		flex: 1;
		padding: 20px;
		background-color: #007bff;
		color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.logo {
			width: 150px;
		}
	}

	.right {
		flex: 1;
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	@media only screen and (max-width: 768px) {
		display: block;

		.login-content {
			display: block;
			width: 100%;

			input,
			button {
				width: 100% !important;
				box-sizing: border-box;
			}
		}
	}
}
