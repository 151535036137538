.login-container {
	.login-content {
		display: flex;
		align-items: center;
		flex-direction: column;

		input {
			width: 376px;
			border: 1px solid #ccc;
			border-radius: 6px;

			@media only screen and (max-width: 768px) {
				width: 82%;
			}

			@media only screen and (max-width: 600px) {
				min-width: auto;
			}
		}

		.login__btn {
			padding: 10px 40px;
			border-radius: 6px;
			width: 400px;

			&:hover {
				background-color: #337cf3;
			}

			@media only screen and (max-width: 768px) {
				width: 90%;
			}
		}

		.remember-me {
			display: flex;
			justify-content: start;
			gap: 2px;
			margin-bottom: 10px;
			input {
				width: auto;
			}
		}
	}

	.login-extra {
		display: flex;
		justify-content: space-between;
		gap: 170px;

		a {
			color: #1856bc;
		}
	}
}
.login {
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
}
.login__container {
	display: flex;
	flex-direction: column;
	text-align: center;
	background-color: #dcdcdc;
	padding: 30px;
}
.login__textBox {
	padding: 10px;
	font-size: 18px;
	margin-bottom: 10px;
}
.login__btn {
	padding: 10px;
	font-size: 18px;
	margin-bottom: 10px;
	border: none;
	color: white;
	background-color: #4285f4;

	&:disabled {
		cursor: not-allowed;
		background-color: #89a7d8;
	}
}
.login__google {
	display: flex;
	justify-content: center;
	gap: 10px;
	background-color: white;
	color: black;
	border: 1px solid #efefef;

	img {
		width: 28px;
	}
	p {
		display: inline-block;
		margin: 0px;
		padding-top: 4px;
	}

	&:hover {
		background-color: #efefef;
	}
}
.login div {
	padding-top: 2px;
	margin-top: 7px;
}

.account-subcaste {
	input {
		// margin-bottom: 20px;
	}
}

.login-page {
	p {
		span {
			font-weight: bold;
		}
	}

	.result-area {
		margin-bottom: 30px;
	}
	.account-actions {
		display: flex;
		flex-direction: row;
		justify-content: start;
		gap: 10px;
	}
}

.or-divider {
	height: 0px;
	border-top: 1px solid #ccc;
	width: 400px;
	margin: 22px 0px 32px;
}
