.faq-item {
	margin-bottom: 40px;
	p {
		margin: 10px 0px;

		a {
			padding: 5px 10px;
		}

		span {
			padding: 5px 2px;
			text-decoration: underline;
		}

		a {
			text-decoration: underline;

			&:hover {
				background-color: rgb(236, 133, 133);
				color: black;
			}
		}
	}
	.question {
		font-weight: bold;
	}
}
