.donate-container {
	p {
		text-align: center;
	}

	img {
		width: 90px;
		margin: 20px 0px;
	}
}

.page.page-donate-cancel {
	.donate-container {
		img {
			display: block;
			margin: 30px auto;
		}
		h1,
		p {
			text-align: center;
		}
		a {
			margin: 0px auto;
			display: block;
			width: 160px;
			text-align: center;
		}
	}
}

.page {
	.donate-container {
		h1,
		p {
			text-align: left;
		}
		h1 {
			margin: 0px;
			padding: 0px;
			padding-top: 10px;
		}
	}
}

#donate-icon {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 40px 0px;

	img {
		width: 100px;

		@media only screen and (max-width: 750px) {
			width: 38%;
		}
	}
}

.donation-wrapper {
	display: flex;
	flex-direction: row;
	gap: 20px;
	justify-content: center;
}
.donation-item {
	background-color: white;
	color: black;
	border: 1px solid #ccc;
	padding: 10px 20px;
	width: 100px;
	text-align: center;
	cursor: pointer;

	&.active {
		color: white;
		background-color: #409def;
	}
}

.donation-button-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 20px;

	button {
		font-size: 24px;
		padding: 17px 62px;
		border-radius: 6px;
		background-color: #409def;
	}
}
