header {
	border-bottom: 1px solid #ccc;
	background-color: white;
	width: 100%;

	position: fixed;
	top: 0px;
	z-index: 1000;

	&.transparent {
		background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent */
		backdrop-filter: blur(10px);
	}

	.header-wrapper {
		display: flex;
		justify-content: space-between;
		width: 90%;
		margin: 0px auto;
		padding: 10px 0px;

		@media only screen and (max-width: 750px) {
			width: 90%;
		}

		.logo-box {
			h1.title {
				@media only screen and (max-width: 750px) {
					display: none;
				}
			}
		}
	}
}

.site-title {
	display: flex;
	justify-content: center;
	gap: 10px;
	padding: 10px 0px;
	cursor: pointer;

	h1.title {
		text-transform: capitalize;
		margin: 4px 0px 0px 0px;
		padding: 0px;
		font-size: 22px;
	}

	.icon-text {
		display: none;
	}

	@media only screen and (max-width: 750px) {
		.icon-tree {
			display: none;
		}
		.icon-text {
			display: block;

			h2 {
				margin: 0px;
				font-size: 20px;
			}
		}
	}
}
.icon-tree {
	width: 28px;
}
