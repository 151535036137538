.search-item {
    cursor: pointer;
    border: 1px solid white;
    border-bottom: 1px solid #ccc;
    padding: 5px 6px;

    &:hover {
        border: 1px solid #ccc;
    }

    h3 {
        text-transform: capitalize;
        margin: 8px 0px;
    }

    p {
        color: #707070;
        margin-top: 0px;

        span {
            text-transform: capitalize;
        }
    }
}

input.form-item-search {
    padding: 16px 0px 16px 20px;
    background-color: #EBEBEB;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
}