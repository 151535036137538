.popup-container {
    position: fixed;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0, 0.2);

    .popup-wrapper {
        display: flex;
        flex-direction: column;
        align-items:flex-end;
        margin-top: 20px;

        .popup-item {
            padding: 20px 40px;
            width: 150px;
            margin-bottom: 15px;
            background-color: white;
            border: 1px solid #ccc;
            box-shadow: 0px 5px 20px rgba(0,0,0, 0.25);

            transform: translateX(-10%);
            transition: all 0.5s;
            
            &::first-letter {
                text-transform: capitalize;
            }

            &.show {
                transform: translateX(0);
            }
        }

    }
}
