.block-wrapper {
	width: 90%;
	margin: 0px auto;
}

.block {
	background-color: #ffffff;
	padding: 20px 20px 30px 20px;
	margin: 20px 0px;
	box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.block-transparent {
	background-color: transparent;
	box-shadow: none;
}

.block-title {
	text-align: center;
	width: 270px;
	margin: 20px auto;

	h1 {
		padding: 20px 0px;
	}

	p {
		padding-bottom: 20px;
	}
}

.block-controls {
	min-height: 100px;

	.buttons-wrapper {
		display: flex;
		gap: 10px;
		flex-direction: row;
		justify-content: start;
		flex-wrap: wrap;

		@media only screen and (max-width: 600px) {
			flex-direction: column;
		}
	}

	h3 {
		@media only screen and (max-width: 600px) {
			text-align: center;
		}
	}
}

.block-header {
	display: flex;
	justify-content: start;
}

.block-person-detail {
	position: relative;
	margin-top: 50px;
	display: flex;
	justify-content: center;

	& > div {
		padding: 30px 40px;
		background-color: white;
		box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.25);
	}

	h2 {
		font-size: 32px;
	}

	.right {
		padding-top: 50px;
		padding-left: 10px;
	}

	img {
		display: block;
		width: 170px;

		@media only screen and (max-width: 600px) {
			margin: 0px auto;
		}
	}

	a {
		display: inline-block;
		padding: 10px 20px;
		color: white;
		background-color: #409def;
	}

	.left {
		position: relative;
	}

	.woman-height-adjust {
		padding-top: 60px;
	}

	@media only screen and (max-width: 600px) {
		flex-direction: column;
		text-align: center;

		.right {
			padding-top: 0px;
			padding-left: 40px;
		}

		.left {
			padding-bottom: 0px;
			box-shadow: none;
		}
	}
}

.block-must-signin {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 460px;
	padding-bottom: 20px;

	a {
		display: inline-block;
		padding: 10px 20px;
		color: white;
		background-color: #409def;
	}

	&.modal-box-wrapper {
		width: 460px;
		border-radius: 6px;

		@media only screen and (max-width: 600px) {
			width: 80%;
		}
	}

	h2 {
		padding: 0px 20px;
		text-align: center;
	}

	@media only screen and (max-width: 600px) {
		width: 80%;
	}
}

.options-wrapper {
	display: flex;
	justify-content: start;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 20px;

	.option {
		cursor: pointer;
		background-color: white;
		padding: 5px 20px;
		border-radius: 6px;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

		&:hover {
			background-color: #39b54b;
			color: white;
		}

		p {
			text-transform: capitalize;
		}
	}
}

.space-block {
	height: 40px;
}

.space-block-2 {
	height: 80px;
}

.block-suggestions {
	textarea {
		width: 60%;
		height: 150px;
		border-color: #cccccc;

		@media only screen and (max-width: 600px) {
			width: 100%;
		}
	}

	button {
		&:disabled {
			cursor: not-allowed;
			opacity: 0.5;
		}
	}
}

.suggestion-uid {
	color: #bfbdbd;
}
.suggestion-msg {
	text-transform: capitalize;
}

.home-info-block {
	background-color: #dce8cf;

	.home-info-block-wrapper {
		width: 70%;
		margin: 0px auto;
		padding: 90px 0px;

		p {
			color: #1f3b21;
			margin: 0px;
			font-size: 25px;
			font-family: "Fragment Mono", monospace;
			line-height: 36px;

			span {
				font-weight: bold;
				font-size: 42px;
				font-family: "Fragment Mono", monospace;
				font-style: italic;
			}

			&.home-info-quote-person {
				text-align: right;
				padding-top: 20px;
			}
		}
	}
}
