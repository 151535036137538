.nav-wrapper {
	padding-top: 15px;
}

nav {
	display: flex;
	gap: 30px;

	li {
		display: block;
	}

	@media only screen and (max-width: 750px) {
		display: none;
	}
}

li.donate-button {
	a {
		padding: 8px 18px;
		color: white;
		background-color: #f4364c;
		border-radius: 5px;
		border: none;

		&:hover {
			background-color: #f25e86;
		}
	}
}

.burger-menu {
	display: none;
	width: 32px;
	img {
		width: 100%;
	}

	@media only screen and (max-width: 750px) {
		display: block;
	}
}

.menu-mobile-container {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	font-size: 30px;
	text-transform: capitalize;
}

.menu-mobile {
	position: relative;
	padding: 0px;

	.modal-box-wrapper {
		position: inherit;
		padding: 50px 10px;
	}
}
.menu-mobile {
	.close {
		top: 0px;
		right: 0px;
		z-index: 1;
	}
}
