@import url("https://fonts.googleapis.com/css2?family=Fragment+Mono:ital@0;1&display=swap");

body {
	background-color: #f0f4f7;
	font-family: "Roboto", sans-serif;
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	font-size: 18px;
	color: rgb(119, 119, 119);
}

h1 {
	text-align: center;
	padding: 20px 0px;

	&.title {
		text-transform: uppercase;
	}
}

h1,
h2,
h3,
h4 {
	font-family: "Montserrat", sans-serif;
}

li {
	list-style: none;
}

a {
	text-decoration: none;
	color: black;
}

button {
	background-color: #e8833a;
	color: white;
	border: none;
	padding: 10px 20px;
	cursor: pointer;

	&:hover {
		background-color: #e5945b;
	}

	&.btn {
		background-color: #409def;
	}
}

a.btn.btn-view {
	display: flex;
	gap: 8px;
	width: 125px;

	.text {
		padding-top: 2px;
	}

	&:hover {
		background-color: #54a6ed;
	}
}

a.btn {
	display: inline-block;
	background-color: #409def;
	color: white;
	border: none;
	padding: 10px 20px;
	margin-bottom: 16px;
	cursor: pointer;

	&:hover {
		background-color: #409def;
	}
}

.container {
	width: 960px;
	margin: 0px auto;

	@media only screen and (max-width: 750px) {
		width: 90%;
	}

	@media only screen and (min-width: 768px) {
		width: 90%;
	}

	@media only screen and (min-width: 700px) {
		width: 90%;
	}
}

.btn-search {
	color: white;
}

#maintenance-logo {
	display: flex;
	justify-content: center;
	margin-top: 230px;
	img {
		display: block;
		width: 70%;

		@media only screen and (min-width: 700px) {
			width: 20%;
		}
	}
}

.page {
	margin-top: 100px;
}

.page-maintenance {
	@media only screen and (max-width: 700px) {
		width: 90%;
		margin: 0px auto;
	}

	p {
		text-align: center;
	}
}

.coffee-icon {
	display: flex;
	flex-direction: row;
	justify-content: center;
	img {
		width: 65px;
		margin-top: 20px;
	}
}

.error-message {
	color: #ff0000;
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 10px;
	padding: 10px;
}

.line {
	background-color: #ccc;
	border-color: #ccc;
	margin: 20px 0px;
}

.step-header {
	span {
		font-size: 22px;
		color: rgb(119, 119, 119);
	}
}

.errors-area {
	color: red;
	padding: 10px 0px 20px;

	ul {
		padding-left: 0px;
		li {
			list-style: disc;
		}
	}
}
