.form-wrapper {
    
    label {
        cursor: pointer;
        display: flex;
        justify-content: center;
        margin-bottom: 5px;

        span {
            display: inline-block;
            padding-top: 3px;
        }
    }
    .form-item {
        margin-bottom: 10px;
    
        input {
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 6px;
            
            &[type=radio] {
                cursor: pointer;
                border: none;
                padding: 0px;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    border: 2px solid #000;
                    margin-right: 8px;
                    vertical-align: middle;
                }
                &:checked::before {
                    background-color: #000;
                }
            }
        }
    }
}

.form-wrapper-children {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .form-item.form-item-gender {
        display: flex;

        label {
            &.gender-title {
                margin-right: 10px;
                padding-top: 3px;
            }
        }
    }

    .form-item.form-item-surname {
        margin-top: 10px;
        margin-bottom: 16px;
    }

    .btn-add-child {
        margin-bottom: 20px;
    }
}